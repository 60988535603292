// conversations.js

import React from 'react'

// react admin
import { List, Pagination, Datagrid, TextField, NumberField, DateField, SelectField, FunctionField, ReferenceField, Edit, Toolbar, SimpleForm, ReferenceManyField } from 'react-admin'
//Edit, SimpleForm, TextInput, DateTimeInput, NumberInput, SelectInput, Create Show, SimpleShowLayout,

import InsertEmoticonTwoToneIcon from '@material-ui/icons/InsertEmoticonTwoTone'
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone'

import { makeStyles } from '@material-ui/core/styles'

import PhoneCallbackTwoToneIcon from '@material-ui/icons/PhoneCallbackTwoTone'
import CallEndTwoToneIcon from '@material-ui/icons/CallEndTwoTone'
import PhoneMissedTwoToneIcon from '@material-ui/icons/PhoneMissedTwoTone'
import PhoneDisabledTwoToneIcon from '@material-ui/icons/PhoneDisabledTwoTone'

// custom icon and name component
import TextIcon from '../Components/TextIcon'
import SidenorIcon from '../Components/SidenorIcon'

/*
create table conversations(
id serial not null primary key,
sessionid VARCHAR(64) UNIQUE,
call integer references calls(id) on delete restrict not null,
vgid VARCHAR(64) UNIQUE,
createtime TIMESTAMP NOT NULL,
lastupdate TIMESTAMP NOT NULL
);
*/

// title for conversation detail
const ConversationTitle = ({ record }) => <span>{record ? 'Detalle de la Conversación' : 'Conversaciones'}</span>

// this one should take all conversation messages
const ConversationPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

// the conversation payload (id,record,resource)
//const ConversationPanel = ({ id, record, resource }) => <pre>{JSON.stringify(record.payload, null, 2)}</pre>

// to show icons in select
const IconNameField = ({ record }) => {
  if (record.id === 'assistant')
    return <SidenorIcon />
  else if (record.id === 'client')
    return <TextIcon icon={<InsertEmoticonTwoToneIcon/>}/>
  else
    return <TextIcon text={record.name} icon={<HelpTwoToneIcon/>}/>
}

export const ConversationList = ({ permissions, ...props }) => (
  <List title={<ConversationTitle />} sort={{ field: 'createtime', order: 'DESC' }} filters={undefined} perPage={25} pagination={<ConversationPagination />} actions={false} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
      {permissions === 'admin' && <NumberField source="id" label="ID" textAlign="left" />}
      {permissions === 'admin' && <TextField source="sessionid" label="Sesión" textAlign="left" />}
      <DateField source="createtime" label="Inicio de la conversación" textAlign="left" locales="es" showTime={true} />
      <ReferenceField source="call" label="Llamada de..." reference="calls" link={false}>
        <TextField source="phonenumber" />
      </ReferenceField>
      {permissions === 'admin' && <TextField source="vgid" label="CID" textAlign="left" />}
      <ReferenceField source="call" label="Duración de la conversación" reference="calls" link={false}>
        <FunctionField render={showTime} textAlign="left" />
      </ReferenceField>
    </Datagrid>
  </List>
)

/*export const ConversationShow = (props) => (
  <Show title={<ConversationTitle />} {...props}>
    <SimpleShowLayout>
      <NumberField source="id" label="ID" textAlign="left" />
      <TextField source="sessionid" label="Sesión" textAlign="left" />
      <ReferenceField source="call" label="Llamada" reference="calls">
        <TextField source="phonenumber" />
      </ReferenceField>
      <TextField source="vgid" label="CID" textAlign="left" />
      <DateField source="createtime" label="Creado" textAlign="left" locales="es" showTime={true} />
      <DateField source="lastupdate" label="Actualizado" textAlign="left" locales="es" showTime={true} />
    </SimpleShowLayout>
  </Show>
)*/

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginInlineEnd: '2rem' },
})

const EditActions = (props) => (
  <Toolbar {...props}>
    <span></span>
  </Toolbar>
)

const showTime = (record) => {
  if (record.payload.CallDuration === undefined) {
    return `llamada en curso...`
  } else {
    return `${Math.floor(record.payload.CallDuration / 60)} min. ${Math.floor(record.payload.CallDuration % 60)} seg.`
  }
}

// to show icons in select
const IconStatusField = ({ record }) => {
  if (record.id === 'queued' || record.id === 'ringing' || record.id === 'in-progress')
    return <TextIcon text={record.name} icon={<PhoneCallbackTwoToneIcon/>}/>
  else if (record.id === 'completed')
    return <TextIcon text={record.name} icon={<CallEndTwoToneIcon/>}/>
  else if (record.id === 'busy' || record.id === 'no-answer')
    return <TextIcon text={record.name} icon={<PhoneMissedTwoToneIcon/>}/>
  else if (record.id === 'failed')
    return <TextIcon text={record.name} icon={<PhoneDisabledTwoToneIcon/>}/>
  else
    return <TextIcon text={record.name} icon={<HelpTwoToneIcon/>}/>
}

export const ConversationEdit = ({ permissions, ...props }) => {
  const classes = useStyles()
  return(<Edit title={<ConversationTitle />} actions={undefined} {...props}>
    <SimpleForm toolbar={<EditActions />} submitOnEnter={false}>
      {permissions === 'admin' && <NumberField source="id" label="ID" />}
      {permissions === 'admin' && <TextField source="sessionid" label="Sesión" />}
      <ReferenceField source="call" label="Llamada de..." reference="calls" formClassName={classes.inlineBlock}  link={false}>
        <TextField source="phonenumber" />
      </ReferenceField>
      {permissions === 'admin' && <TextField source="vgid" label="CID" />}
      <ReferenceField source="call" label="Duración de la conversación" reference="calls" formClassName={classes.inlineBlock}  link={false}>
        <FunctionField render={showTime} textAlign="left" />
      </ReferenceField>
      <ReferenceField source="call" label="Estado de la llamada" reference="calls" formClassName={classes.inlineBlock}  link={false}>
        <SelectField source="callstatus" optionText={<IconStatusField />} choices={[
          { id: 'queued', name: 'Encolada' },
          { id: 'ringing', name: 'Llamando' },
          { id: 'in-progress', name: 'En curso' },
          { id: 'completed', name: 'Finalizada' },
          { id: 'busy', name: 'Ocupado/a' },
          { id: 'failed', name: 'Fallada' },
          { id: 'no-answer', name: 'No responde' },
        ]} />
      </ReferenceField>
      <DateField source="createtime" label="Inicio de la conversación" locales="es" showTime={true} formClassName={classes.inlineBlock} />
      <DateField source="lastupdate" label="Fin de la conversación" locales="es" showTime={true} formClassName={classes.inlineBlock} />
      <ReferenceManyField label="Mensajes de la conversación" reference="messages" target="conversation" sort={{field: 'createtime', order: 'ASC'}} perPage={5000} fullWidth={true}>
        <Datagrid>
          {permissions === 'admin' && 
            <ReferenceField source="id" label="ID Mensaje" reference="messages">
                <TextField source="id" />
              </ReferenceField>}
          <TextField multiline="true" source="text" label="Mensaje" />
          <SelectField source="origin" label="Mensaje de..." optionText={<IconNameField />} choices={[
            { id: 'assistant', name: 'Asistente virtual' },
            { id: 'client', name: 'Cliente' },
          ]} />
          <DateField source="createtime" label="Fecha del mensaje" textAlign="left" locales="es" showTime={true} />
        </Datagrid>
      </ReferenceManyField>
    </SimpleForm>
  </Edit>
)}
