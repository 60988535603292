// in src/Dashboard.js
import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Button from '@material-ui/core/Button'

export default (props) => {
  //console.log(props)
  const subtitle = (
    <span>¡Llama al <Button color="primary" onClick={()=> window.open("tel:944581104", "_blank")}>+34 944 58 11 04</Button> para comenzar!</span>
  )
  return (
  <div style={{marginTop: '24px !important'}}>
    <Card>
      <CardHeader title="Bienvenido/a al administrador de Sidenor RPA"  subheader={subtitle} />

      <CardContent>Selecciona un elemento a la izquierda para obtener más detalles acerca del mismo...</CardContent>
      <CardContent><small>Desarrollado por Kevo Technologies (2019-2020)</small></CardContent>
    </Card>
  </div>
)}
