// App.js

import React from 'react'

// react admin
import { Admin, Resource } from 'react-admin'
//fetchUtils

// resources
import { CallList } from './resources/calls'
import { ConversationList, ConversationEdit } from './resources/conversations'
import { MessageList, MessageEdit } from './resources/messages'
import { StatisticsList } from './resources/statistics'
import { ConfigurationList } from './resources/configuration'

// icons
import PermPhoneMsgTwoToneIcon from '@material-ui/icons/PermPhoneMsgTwoTone'
import ChatTwoToneIcon from '@material-ui/icons/ChatTwoTone'
import AssessmentIcon from '@material-ui/icons/Assessment'
import RecordVoiceOverTwoToneIcon from '@material-ui/icons/RecordVoiceOverTwoTone'

// main custom theme
import sidenorTheme from './themes/sidenorTheme'

// custom login page background
import Login from './Auth/Login.js'

// custom sidebar and top navigation bar
import Layout from './Layouts/Layout.js'

// main dashboard
import Dashboard from './Dashboards/Dashboard'

// auth providers
import authProvider from './authProviders/authProvider'

// data providers
import customDataProvider from './dataProviders/dataProvider'
//import jsonServerProvider from 'ra-data-json-server'

// translations
// https://github.com/BlackBoxVision/ra-language-spanish
import spanishMessages from '@blackbox-vision/ra-language-spanish'
// https://marmelab.com/react-admin/Translation.html
import polyglotI18nProvider from 'ra-i18n-polyglot'
// maybe change for https://github.com/marmelab/react-admin/blob/master/packages/ra-language-english/src/index.ts and translate?

const i18nProvider = polyglotI18nProvider(() => spanishMessages, 'es', {allowMissing: true})

// dataprovider
/*const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({
          Accept: 'application/json',
          Type: 'cors'
        })
    }
    // add your own headers here
    options.headers.set('Authorization', localStorage.getItem('authorization'))
    return fetchUtils.fetchJson(url, options)
}
const dataProvider = jsonServerProvider('https://api.sidenor.kevo.sh/admin', httpClient)*/

// for removing # in routes
const createHistory = require('history').createBrowserHistory
const history = createHistory()

// main app declaration
const App = (props) => {
  //console.log(props)
  return (
  // dashboard and resources
  <Admin disableTelemetry style={{flexGrow: 1}} history={history} title="Sidenor RPA - Administrador" i18nProvider={i18nProvider} theme={sidenorTheme} loginPage={Login} layout={Layout} dashboard={Dashboard} authProvider={authProvider} dataProvider={customDataProvider}>
    {permissions => [
      permissions === 'admin'
      ? <Resource name="calls" options={{ label: 'Llamadas' }} list={CallList} show={null} create={null} edit={null} icon={PermPhoneMsgTwoToneIcon} />
      : <Resource name="calls" options={{ label: 'Llamadas' }} list={null} show={null} create={null} edit={null} icon={PermPhoneMsgTwoToneIcon} />,
      <Resource name="conversations" options={{ label: 'Conversaciones' }} list={ConversationList} show={null} create={null} edit={ConversationEdit} icon={PermPhoneMsgTwoToneIcon} />,
      permissions === 'admin'
      ? <Resource name="messages" options={{ label: 'Mensajes' }} list={MessageList} show={null} create={null} edit={MessageEdit} icon={ChatTwoToneIcon} />
      : <Resource name="messages" options={{ label: 'Mensajes' }} list={null} show={null} create={null} edit={MessageEdit} icon={ChatTwoToneIcon} />,
      <Resource name="statistics" options={{ label: 'Estadísticas' }} list={StatisticsList} show={null} create={null} edit={null} icon={AssessmentIcon} />,
      <Resource name="configuration" options={{ label: 'Configuración' }} list={ConfigurationList} show={null} create={null} edit={null} icon={RecordVoiceOverTwoToneIcon} />
    ]}
</Admin>
)}

export default App
