import React from "react"
import { Layout } from 'react-admin'
import AppBar from './AppBar'
import Sidebar from './Sidebar'
import Menu from './Menu'

export default (props) => {
  return (
    <Layout 
    style={{background: 'url(/network-spheres-bg.jpg) no-repeat', backgroundSize: 'cover'}} 
    appBar={AppBar} 
    sidebar={Sidebar} 
    menu={Menu} 
    {...props} />
  )
}
