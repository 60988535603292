import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import { Notification } from 'react-admin';
import { useTranslate, useLogin, useNotify } from 'ra-core';
const useStyles = makeStyles(theme => {
  //console.log(theme)
  return ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'url(/network-spheres-bg.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
        maxHeight: '400px'
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: 'rgba(0,0,0,0)',
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
})});
const renderInput = ({ meta: { touched, error } = { touched: false, error: undefined }, input: { ...inputProps }, ...props }) => (React.createElement(TextField, Object.assign({ error: !!(touched && error), helperText: touched && error }, inputProps, props, { fullWidth: true })));
const { Form } = withTypes();
const Login = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();
    const handleSubmit = (auth) => {
        setLoading(true);
        //console.log(auth)
        window.grecaptcha.ready(function() {
          window.grecaptcha.execute('6Le65aIZAAAAAEWB-Vivv6xE0j4O2q7nYbOcyCD_', {action: 'login'}).then(function(token) {
              // Add your logic to submit to your backend server here.
              //console.log(token)
              auth.token = token
              //console.log(auth)
              login(auth, location.state ? location.state.nextPathname : '/').catch((error) => {
                  setLoading(false);
                  notify(typeof error === 'string'
                      ? error
                      : typeof error === 'undefined' || !error.message
                          ? 'ra.auth.sign_in_error'
                          : error.message, 'warning');
              });
          });
        });

    };
    const validate = (values) => {
        const errors = {};
        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };
    return (React.createElement(Form, { onSubmit: handleSubmit, validate: validate, render: ({ handleSubmit }) => (React.createElement("form", { onSubmit: handleSubmit, noValidate: true },
            React.createElement("div", { className: classes.main },
                React.createElement(Card, { className: classes.card },
                    React.createElement("div", { className: classes.avatar },
                        React.createElement(Avatar, { className: classes.icon },
                            React.createElement(LockIcon, null))),
                    React.createElement("div", { className: classes.form },
                        React.createElement("div", { className: classes.input },
                            React.createElement(Field, { autoFocus: true, name: "username", 
                                // @ts-ignore
                                component: renderInput, label: translate('ra.auth.username'), disabled: loading })),
                        React.createElement("div", { className: classes.input },
                            React.createElement(Field, { name: "password", 
                                // @ts-ignore
                                component: renderInput, label: translate('ra.auth.password'), type: "password", disabled: loading }))//,
                        // captcha
                        //React.createElement("input", { type: 'text', 'name': 'token', 'id': 'token' })
                        ),
                    React.createElement(CardActions, { className: classes.actions },
                        React.createElement(Button, { variant: "contained", type: "submit", color: "primary", disabled: loading, fullWidth: true },
                            loading && (React.createElement(CircularProgress, { size: 25, thickness: 2 })),
                            translate('ra.auth.sign_in')))),
                React.createElement(Notification, null)))) }));
};
Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};
// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props) => {
  //console.log(props)
  return (React.createElement(ThemeProvider, { theme: createMuiTheme(props.theme) },
    React.createElement(Login, Object.assign({}, props))))};
export default LoginWithTheme;