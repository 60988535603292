// statistics.js

import React from 'react'

import Avatar from '@material-ui/core/Avatar'

export default (props) => (
  <span style={{alignContent: 'center', display: 'flex'}}>
    <span style={{lineHeight: 2, marginRight: '8px'}}>{props.text}</span>
    <Avatar alt="Sidenor" src="/sidenorIcon.png" style={{height: '32px', width: '32px'}} />
  </span>
)