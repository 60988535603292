// configuration.js

import React from 'react'
import { useState, useEffect } from 'react'

// material ui stuff for config
import Button from '@material-ui/core/Button'
import Slider from '@material-ui/core/Slider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone'
import IndeterminateCheckBoxTwoToneIcon from '@material-ui/icons/IndeterminateCheckBoxTwoTone'
import AddBoxTwoToneIcon from '@material-ui/icons/AddBoxTwoTone'

const toneText = (value) => {
  return `${value}Hz`
}

const prosodyText = (value) => {
  return `${value}ppm`
}

export const ConfigurationList = (props) => {
  const [pitch, setPitch] = useState(0)
  const [prosody, setProsody] = useState(200)
  const [dev, setDev] = useState(false)
    useEffect(() => {
      window.fetch("https://api.sidenor.kevo.sh/admin/get-config/", {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem('authorization'),
          Accept: 'application/json'
        }
      })
      .then(async (response) => {
        if (response.ok) {
          const json = await response.json()
          setPitch(Number(json.pitch))
          setProsody(Number(json.prosody))
          setDev(json.dev)
        }
      })
    }, [])
    const handlePitch = (event, newPitch) => {
      setPitch(newPitch);
    }
    const handleProsody = (event, newProsody) => {
      setProsody(newProsody);
    }
    const handleDev = (event, newDev) => {
      setDev(newDev);
    }
    const saveChanges = () => {
      window.fetch("https://api.sidenor.kevo.sh/admin/set-config/", {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem('authorization'),
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          pitch: `${pitch}`,
          prosody: `${prosody}`,
          dev: dev
        })
      })
      .then((response) => {
        if (response.ok) {
          alert("OK")
        } else {
          alert("NOK")
        }
      })
    }
  return (
    <div>
      <div style={{margin: '48px'}}>
        <Typography id="discrete-slider" gutterBottom>
          Configurar tono de habla (modificación del tono)
        </Typography>
        <Grid container spacing={2}>
          <Grid item style={{flexGrow: '0.05'}}>
            <IndeterminateCheckBoxTwoToneIcon style={{margin: '0 auto', display: 'flex'}} />
          </Grid>
          <Grid item  style={{flexGrow: '0.9'}}>
            <Slider
              value={pitch}
              defaultValue={0}
              getAriaValueText={toneText}
              valueLabelFormat={toneText}
              onChange={handlePitch}
              aria-labelledby="discrete-slider-always"
              valueLabelDisplay="on"
              step={1}
              marks
              min={-80}
              max={80}
            />
          </Grid>
          <Grid item style={{flexGrow: '0.05'}}>
            <AddBoxTwoToneIcon style={{margin: '0 auto', display: 'flex'}} />
          </Grid>
        </Grid>
      </div>
      <div style={{margin: '48px'}}>
        <Typography id="discrete-slider" gutterBottom>
          Configurar velocidad de habla (palabras por minuto)
        </Typography>
        <Grid container spacing={2}>
          <Grid item style={{flexGrow: '0.05'}}>
            <IndeterminateCheckBoxTwoToneIcon style={{margin: '0 auto', display: 'flex'}} />
          </Grid>
          <Grid item  style={{flexGrow: '0.9'}}>
            <Slider
              value={prosody}
              defaultValue={200}
              getAriaValueText={prosodyText}
              onChange={handleProsody}
              aria-labelledby="discrete-slider-always"
              valueLabelDisplay="on"
              step={10}
              marks
              min={80}
              max={330}
            />
          </Grid>
          <Grid item style={{flexGrow: '0.05'}}>
            <AddBoxTwoToneIcon style={{margin: '0 auto', display: 'flex'}} />
          </Grid>
        </Grid>
      </div>
      <div style={{margin: '48px'}}>
        <Typography id="discrete-slider" gutterBottom>
          La siguiente configuración controla de dónde se cogen los datos de la aplicación
        </Typography>
        <Grid container spacing={2}>
          <Grid item style={{flexGrow: '0.05'}}>
          </Grid>
          <Grid item  style={{flexGrow: '0.9'}}>
            <FormControlLabel
              control={
                <Switch
                  checked={dev}
                  onChange={handleDev}
                  name="dev"
                  color="primary"
                />
              }
              label="Utilizar el entorno de desarrollo"
            />
          </Grid>
          <Grid item style={{flexGrow: '0.05'}}>
          </Grid>
        </Grid>
      </div>
      <div style={{margin: '48px'}}>
        <Button onClick={saveChanges} variant="contained" color="primary" style={{display: 'flex', margin: '0 auto'}}>
          Guardar<SaveTwoToneIcon style={{marginLeft: '12px'}} />
        </Button>
      </div>
    </div>
)}

/*export const StatisticsList = (props) => (
  <List title={<StatisticsTitle />} filters={false} pagination={false} bulkActionButtons={false} {...props}>
  </List>
)*/
