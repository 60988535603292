// in src/authProvider.js
export default {
  // called when the user attempts to log in
  login: ({ username, password, token }) => {
    const authorization = 'Basic ' + new Buffer(`${username}:${password}`).toString('base64')
    //console.log(token)
    return fetch('https://api.sidenor.kevo.sh/admin/', {
      method: 'POST',
      headers: {
        Authorization: authorization,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: token
      })
    })
    .then(res => {
      if (res.ok) {
        //localStorage.setItem('username', username)
        //localStorage.setItem('password', password)
        localStorage.setItem('authorization', authorization)
        localStorage.setItem('permissions', username)
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    })
  },
  // called when the user clicks on the logout button
  logout: () => {
    //localStorage.removeItem('username')
    //localStorage.removeItem('password')
    localStorage.removeItem('authorization')
    localStorage.removeItem('permissions')
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      //localStorage.removeItem('username')
      //localStorage.removeItem('password')
      localStorage.removeItem('authorization')
      localStorage.removeItem('permissions')
      return Promise.reject()
    } else {
      return Promise.resolve()
    }
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    /*return (localStorage.getItem('username') && localStorage.getItem('password'))
      ? Promise.resolve()
      : Promise.reject()*/
    return localStorage.getItem('authorization') ? Promise.resolve() : Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const role = localStorage.getItem('permissions')
    return role ? Promise.resolve(role) : Promise.reject()
  }
}
