import React from "react"
import { AppBar } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { forwardRef } from 'react'
import { useLogout } from 'react-admin'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
//import ExitIcon from '@material-ui/icons/ExitToApp'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'

// logo for navigation

const useStyles = makeStyles({
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    logo: {
      flex: 1,
      height: '38px',
      display: 'flex',
      width: 'auto',
      /*borderRadius: '16px 4px 16px 4px',
      backgroundColor: 'rgb(240, 170, 180)',*/
      //'-webkit-filter': 'drop-shadow(0px 0px 2px #fff))',
      //filter: 'drop-shadow(0px 0px 2px #fff)',
      //'-webkit-text-fill-color': 'white', /* Will override color (regardless of order) */
      //'-webkit-text-stroke-width': '1px',
      //'-webkit-text-stroke-color': 'black',
      //'-webkit-box-shadow': '3px 3px 5px 6px #fff',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      //'-moz-box-shadow': '3px 3px 5px 6px #fff',  /* Firefox 3.5 - 3.6 */
      //'box-shadow': '3px 3px 5px 6px #fff'  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    },
    spacer: {
      flex: 1,
    },
})

// custom logout button without that empty menu
const LogoutButton = forwardRef((props, ref) => {
  const logout = useLogout()
  const handleClick = () => logout()
  return (
    <Tooltip title="Cerrar sesión">
      <IconButton 
        aria-label="cerrar sesión"
        style={{color: 'inherit'}}
        onClick={handleClick}
        ref={ref}>
          <PowerSettingsNewIcon />
      </IconButton>
    </Tooltip>
  )
})

export default (props) => {
  const classes = useStyles()
  //console.log(props)
  return (
    <AppBar userMenu={<LogoutButton/>} {...props}>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
      <div className={classes.spacer}>
        <a href="/"> 
          <img 
          src="/sidenor_logo_blanco_test.png" 
          alt="Sidenor Aceros Especiales SL" 
          className={classes.logo} />
        </a>
      </div>
    </AppBar>
  )
}
