// calls.js

import React from 'react'

// material ui media queryes for responsive
//import { useMediaQuery } from '@material-ui/core'

// react admin
import { List, Pagination, Datagrid, TextField, NumberField, DateField, FunctionField, SelectField } from 'react-admin'
//Edit, SimpleForm, TextInput, DateTimeInput, NumberInput, SelectInput, Create Show, SimpleShowLayout, RichTextField Create Edit, Toolbar, SimpleForm, 

import PhoneCallbackTwoToneIcon from '@material-ui/icons/PhoneCallbackTwoTone'
import CallEndTwoToneIcon from '@material-ui/icons/CallEndTwoTone'
import PhoneMissedTwoToneIcon from '@material-ui/icons/PhoneMissedTwoTone'
import PhoneDisabledTwoToneIcon from '@material-ui/icons/PhoneDisabledTwoTone'
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone'

// custom icon and name component
import TextIcon from '../Components/TextIcon'

/*
CREATE TYPE callStatusChoices AS ENUM ('queued','ringing','in-progress','completed','busy','failed','no-answer');
ID SERIAL NOT NULL PRIMARY KEY,
callSid VARCHAR(64) UNIQUE NOT NULL,
phoneNumber VARCHAR(13) NOT NULL,
callStatus callStatusChoices NOT NULL,
lastUpdate TIMESTAMP NOT NULL,
payload JSON NOT NULL,
createtime TIMESTAMP NOT NULL,
*/

// title for call detail
const CallTitle = ({ record }) => <span>{record ? 'Detalle de la Llamada' : 'Llamadas'}</span>

const CallPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

// the call payload (id,record,resource)
const CallPanel = ({ id, record, resource }) => <pre>{JSON.stringify(record.payload, null, 2)}</pre>

// to show icons in select
const IconStatusField = ({ record }) => {
  if (record.id === 'queued' || record.id === 'ringing' || record.id === 'in-progress')
    return <TextIcon text={record.name} icon={<PhoneCallbackTwoToneIcon/>}/>
  else if (record.id === 'completed')
    return <TextIcon text={record.name} icon={<CallEndTwoToneIcon/>}/>
  else if (record.id === 'busy' || record.id === 'no-answer')
    return <TextIcon text={record.name} icon={<PhoneMissedTwoToneIcon/>}/>
  else if (record.id === 'failed')
    return <TextIcon text={record.name} icon={<PhoneDisabledTwoToneIcon/>}/>
  else
    return <TextIcon text={record.name} icon={<HelpTwoToneIcon/>}/>
}

const showTime = (record) => {
  if (record.payload.CallDuration === undefined) {
    return `llamada en curso...`
  } else {
    return `${Math.floor(record.payload.CallDuration / 60)} min. ${Math.floor(record.payload.CallDuration % 60)} seg.`
  }
}

export const CallList = ({permissions, ...props}) => (
  <List title={<CallTitle />} filters={undefined} perPage={25} pagination={<CallPagination />} actions={false} bulkActionButtons={false} {...props}>
    <Datagrid rowClick={undefined} expand={permissions === 'admin' ? CallPanel : null}>
      {permissions === 'admin' && <NumberField source="id" label="ID" textAlign="left" />}
      {permissions === 'admin'&& <TextField source="callsid" label="SID" textAlign="left" />}
      <TextField source="phonenumber" label="Teléfono" textAlign="left" />
      <FunctionField label="Duración" render={showTime} textAlign="left" />
      <SelectField source="callstatus" label="Estado" optionText={<IconStatusField />} choices={[
        { id: 'queued', name: 'Encolada' },
        { id: 'ringing', name: 'Llamando' },
        { id: 'in-progress', name: 'En curso' },
        { id: 'completed', name: 'Finalizada' },
        { id: 'busy', name: 'Ocupado/a' },
        { id: 'failed', name: 'Fallada' },
        { id: 'no-answer', name: 'No responde' },
      ]} />
      <DateField source="createtime" label="Creada" textAlign="left" locales="es" showTime={true} />
      <DateField source="lastupdate" label="Actualizada" textAlign="left" locales="es" showTime={true} />
    </Datagrid>
  </List>
)

/*export const CallShow = (props) => (
  <Show title={<CallTitle />} {...props}>
    <SimpleShowLayout>
      <NumberField source="id" label="ID" textAlign="left" />
      <TextField source="phonenumber" label="Teléfono" textAlign="left" />
      <TextField source="callsid" label="SID" textAlign="left" />
      <TextField source="callstatus" label="Estado" textAlign="left" />
      <DateField source="lastupdate" label="Actualizado" textAlign="left" locales="es" showTime={true} />
      <RichTextField multiline source="payload" label="Detalle" />
    </SimpleShowLayout>
  </Show>
)*/

/*const EditActions = (props) => (
  <Toolbar {...props}>
    <span></span>
  </Toolbar>
)*/

/*export const CallEdit = ({permissions, ...props}) => (
  <Edit title={<CallTitle />} actions={undefined} {...props}>
    <SimpleForm toolbar={<EditActions />} submitOnEnter={false}>
      {permissions === 'admin' && <NumberField source="id" label="ID" />}
      <TextField source="phonenumber" label="Teléfono" />
      {permissions === 'admin'
        ? <TextField source="callsid" label="SID" textAlign="left" />
        : null}
      <SelectField source="callstatus" label="Estado" choices={[
        { id: 'queued', name: 'Encolada' },
        { id: 'ringing', name: 'Llamando' },
        { id: 'in-progress', name: 'En curso' },
        { id: 'completed', name: 'Finalizada' },
        { id: 'busy', name: 'Ocupado/a' },
        { id: 'failed', name: 'Fallada' },
        { id: 'no-answer', name: 'No responde' },
      ]} />
      <DateField source="createtime" label="Creada" textAlign="left" locales="es" showTime={true} />
      <DateField source="lastupdate" label="Actualizada" textAlign="left" locales="es" showTime={true} />
    </SimpleForm>
  </Edit>
)*/

/*export const CallCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <NumberInput source="id" label="ID" helperText="Identificador de la llamada en la BBDD" fullWidth={true} />
      <TextInput source="phonenumber" label="Teléfono" helperText="Número de teléfono desde donde se ha realizado esta comunicación" fullWidth={true} />
      <TextInput source="callsid" label="SID" helperText="Identificador único proporcionado por el servicio de telecomunicaciones" fullWidth={true} />
      <SelectInput source="callstatus" label="Estado" helperText="Último estado de la llamada" fullWidth={true} emptyText="Selecciona un estado..." choices={[
        { id: 'queued', name: 'Encolada' },
        { id: 'ringing', name: 'Llamando' },
        { id: 'in-progress', name: 'En curso' },
        { id: 'completed', name: 'Finalizada' },
        { id: 'busy', name: 'Ocupado/a' },
        { id: 'failed', name: 'Fallada' },
        { id: 'no-answer', name: 'No responde' },
      ]} />
      <DateTimeInput source="lastupdate" label="Actualizado" helperText="Fecha de la última actualización de este registro" fullWidth={true} />
    </SimpleForm>
  </Create>
)*/
