// messages.js

import React from 'react'

// react admin
import { List, Pagination, Datagrid, TextField, NumberField, DateField, Filter, TextInput, ReferenceField, Edit, SimpleForm, SelectField } from 'react-admin'
// Show, SimpleShowLayout, 

import InsertEmoticonTwoToneIcon from '@material-ui/icons/InsertEmoticonTwoTone'
import SettingsInputComponentTwoToneIcon from '@material-ui/icons/SettingsInputComponentTwoTone'
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone'

// custom icon and name component
import TextIcon from '../Components/TextIcon'

/*
CREATE TYPE originChoices AS ENUM ('assistant','client');

create table messages(
id serial not null primary key,
conversation integer references conversations(id) on delete cascade not null,
origin originChoices NOT NULL,
createtime TIMESTAMP NOT NULL,
text TEXT NOT NULL,
payload JSON NOT NULL
);
*/

// title for message detail
const MessageTitle = ({ record }) => <span>{record ? 'Ver Mensaje' : 'Mensajes'}</span>

// available message filters
const MessageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar un mensaje..." source="q" alwaysOn />
    </Filter>
)

// the message payload (id,record,resource)
const MessagePanel = ({ id, record, resource }) => <pre>{JSON.stringify(record.payload, null, 2)}</pre>

const MessagePagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

// to show icons in select
const IconNameField = ({ record }) => {
  if (record.id === 'assistant')
    return <TextIcon text={record.name} icon={<SettingsInputComponentTwoToneIcon/>}/>
  else if (record.id === 'client')
    return <TextIcon text={record.name} icon={<InsertEmoticonTwoToneIcon/>}/>
  else
    return <TextIcon text={record.name} icon={<HelpTwoToneIcon/>}/>
}

export const MessageList = (props) => (
  <List title={<MessageTitle />} filters={<MessageFilter />} pagination={<MessagePagination />} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit" expand={MessagePanel}>
      <NumberField source="id" label="ID" textAlign="left" />
      <ReferenceField source="conversation" label="Conversación" reference="conversations">
        <TextField source="id" />
      </ReferenceField>
      <SelectField source="origin" label="Orígen" optionText={<IconNameField />} choices={[
        { id: 'assistant', name: 'Asistente virtual' },
        { id: 'client', name: 'Cliente' },
      ]} />
      <TextField multiline source="text" label="Texto" textAlign="left" />
      <DateField source="createtime" label="Creado" textAlign="right" locales="es" showTime={true} />
    </Datagrid>
  </List>
)

/*export const MessageShow = (props) => (
  <Show title={<MessageTitle />} {...props}>
    <SimpleShowLayout>
      <NumberField source="id" label="ID" textAlign="left" />
      <ReferenceField source="conversation" label="Conversación" reference="conversations">
        <TextField source="session" />
      </ReferenceField>
      <TextField source="origin" label="Orígen" textAlign="left" />
      <TextField source="text" label="Texto" textAlign="left" />
      <DateField source="createtime" label="Creado" textAlign="right" locales="es" showTime={true} />
    </SimpleShowLayout>
  </Show>
)*/

export const MessageEdit = props => (
  <Edit title={<MessageTitle />} actions={undefined} {...props}>
    <SimpleForm toolbar={false} submitOnEnter={undefined}>
      <NumberField source="id" label="ID" helperText="Identificador del mensaje en la BBDD" fullWidth={true} />
      <ReferenceField source="conversation" label="Conversación" reference="conversations">
        <TextField source="sessionid" />
      </ReferenceField>
      <SelectField source="origin" label="Orígen" helperText="Orígen del mensaje, es decir, quién ha mandado este mensaje" fullWidth={true} emptyText="Selecciona un orígen..." choices={[
        { id: 'assistant', name: 'Asistente virtual' },
        { id: 'client', name: 'Cliente' },
      ]} />
      <TextField multiline source="text" label="Texto" helperText="Texto del mensaje" fullWidth={true} />
      <DateField source="createtime" label="Creado" helperText="Fecha de creación de este registro" fullWidth={true} />
    </SimpleForm>
  </Edit>
)
