import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    // see https://material-ui.com/customization/color/#official-color-tool
    // color tool https://material.io/resources/color/#!/
    primary: {
      light: '#ff5444',
      main: '#e2001a',
      dark: '#a70000',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#ff5444',
      main: '#e2001a',
      dark: '#a70000',
      contrastText: '#ffffff'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiButton: { // override the styles of all instances of this component
      root: { // Name of the rule
        //color: 'white', // Some CSS
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: 'rgba(0,0,0,0)',
        backgroundImage: 'url(/logo192.png)',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        width: '128px',
        height: '128px'
      }
    },
    MuiPaper: {
      root: {
        display: 'flex !important',
        marginTop: 'auto !important',
        marginBottom: 'auto !important',
        flexDirection: 'column'
      },
      rounded: {
        padding: '12px'
      }
    }
  },
})
