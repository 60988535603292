// statistics.js

import React from 'react'

// react admin
//import { List } from 'react-admin'

// title for call detail
//const StatisticsTitle = () => <span>Estadísticas</span>

/*const getCookie = (name) => {
  // from https://plainjs.com/javascript/utilities/set-cookie-get-cookie-and-delete-cookie-5/
  var v = document.cookie.match('(^|) ?' + name + '=([^]*)(|$)')
  return v ? v[2] : null
}*/

const setCookie = (name, value, days, domain) => {
  // modified from https://plainjs.com/javascript/utilities/set-cookie-get-cookie-and-delete-cookie-5/
  var d = new Date()
  d.setTime(d.getTime() + 24*60*60*1000*days)
  document.cookie = `${name}=${value};domain=${domain};path=/;expires=${d.toGMTString()}`
}

export const StatisticsList = (props) => {
  //const cookie = getCookie('sidenorstats')
  setCookie('sidenorstats', 'qd96qqiZYBDs9sGKMP8978z3N8l3jpEUqewkMfcBSxHL7NTqIb2fdkftegyIK5jfEo59', 3650, '.kevo.sh')
  //window.cookie = 'sidenorstats=TESTINGXmax-age=3153600000domain=sidenorstats.kevo.shexpires=Fri, 01-01-2100 12:00:00 GMTsecure=true'
  return (
  <div style={{backgroundColor: 'transparent', display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
    <iframe 
    src="https://sidenorstats.kevo.sh/d/jbz1sxIWk/estadisticas-de-sidenor-rpa?orgId=1&refresh=5s&kiosk=tv" 
    title="Estadísticas - Sidenor Aceros Especiales SL"
    scrolling="no"
    frameBorder="0"
    style={{height: '100%', width: '100%', opacity: 0.9}}
    ></iframe> 
    {false && 'grafana admin => Ar9t0$gKzyWg2N'}
    {false && 'grafana db => wAzIkHuVI3tm967hXkq63cHS'}
  </div>
)}

/*export const StatisticsList = (props) => (
  <List title={<StatisticsTitle />} filters={false} pagination={false} bulkActionButtons={false} {...props}>
  </List>
)*/
