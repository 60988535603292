import { stringify } from 'query-string'
//import { fetchUtils } from 'ra-core'
//import { HttpError } from 'react-admin'

// base url
const apiUrl = 'https://api.sidenor.kevo.sh/admin'

//const httpClient = null

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react"
 * import { Admin, Resource } from 'react-admin'
 * import simpleRestProvider from 'ra-data-simple-rest'
 *
 * import { PostList } from './posts'
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * )
 *
 * export default App
 */

/*const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  options.headers.set('Authorization', localStorage.getItem('authorization'))
  return fetchUtils.fetchJson(url, options)
}*/

export default {
  getList: async (resource, params) => {
    const query = {
      page: JSON.stringify(params.pagination.page),
      perPage: JSON.stringify(params.pagination.perPage),
      filter: JSON.stringify(params.filter),
      order: `${params.sort.field} ${params.sort.order}`
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    // do the request
    const json = await window.fetch(url, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('authorization'),
        Accept: 'application/json',
        'Access-Control-Request-Headers': 'Content-Range',
        'Access-Control-Allow-Headers': 'Content-Range'
      }
    })
    .then(async (response) => {
      //console.log(response)
      //console.log(response.headers)
      //response.headers.forEach(console.log)
      if (response.ok) {
        const json = await response.json()
        return {
          data: json.rows,
          total: Number(json.total)
        }
      }
      else {
        throw new Error('La petición al servidor ha fallado')
      }
    })
    .then(json => {
      return json
    })
    //console.log(json)
    return json
  },

  getOne: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`
    // do the request
    const json = await window.fetch(url, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('authorization'),
        Accept: 'application/json'
      }
    })
    .then(async (response) => {
      //console.log(response)
      //console.log(response.headers)
      //response.headers.forEach(console.log)
      if (response.ok) {
        const json = await response.json()
        return {
          data: json
        }
      }
      else {
        throw new Error('La petición al servidor ha fallado')
      }
    })
    .then(json => {
      //console.log(json)
      return json
    })
    //console.log(json)
    return json
  },

  getMany: async (resource, params) => {
    //console.log(resource)
    //console.log(params)
    const query = {
      ids: JSON.stringify(params.ids),
    }
    const url = `${apiUrl}/${resource}-many?${stringify(query)}`
    //console.log(url)
    // do the request
    const json = await window.fetch(url, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('authorization'),
        Accept: 'application/json',
        'Access-Control-Request-Headers': 'Content-Range',
        'Access-Control-Allow-Headers': 'Content-Range'
      }
    })
    .then(async (response) => {
      if (response.ok) {
        const json = await response.json()
        return {
          data: json.rows
        }
      }
      else {
        throw new Error('La petición al servidor ha fallado')
      }
    })
    .then(json => {
      return json
    })
    //console.log(json)
    return json
  },

  getManyReference: async (resource, params) => {
    const query = {
      id: JSON.stringify(params.id),
      target: params.target,
      page: JSON.stringify(params.pagination.page),
      perPage: JSON.stringify(params.pagination.perPage),
      filter: JSON.stringify(params.filter),
      order: `${params.sort.field} ${params.sort.order}`
    }
    const url = `${apiUrl}/${resource}-manyref?${stringify(query)}`
    // do the request
    const json = await window.fetch(url, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('authorization'),
        Accept: 'application/json'
      }
    })
    .then(async (response) => {
      //console.log(response)
      //console.log(response.headers)
      //response.headers.forEach(console.log)
      if (response.ok) {
        const json = await response.json()
        //console.log(json)
        return {
          data: json.rows,
          total: Number(json.total)
        }
      }
      else {
        throw new Error('La petición al servidor ha fallado')
      }
    })
    .then(json => {
      return json
    })
    //console.log(json)
    return json
  },

  /*create: async (resource, params) => {
    const query = {
      data: JSON.stringify(params.data)
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    const json = await window.fetch(url, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('authorization'),
        Accept: 'application/json'
      }
    })
    .then(async (response) => {
      if (response.ok) {
        const json = await response.json()
        return {
          data: json.rows
        }
      }
      else {
        throw new Error('La petición al servidor ha fallado')
      }
    })
    .then(json => {
      return json
    })
    //console.log(json)
    return json
  },

  update: (resource, params) => {
    return undefined
  },

  updateMany: (resource, params) => {
    return undefined
  },*/

/*export default {
  // Search for resources
  getList: (resource, params) => {
    //console.log(params)
    // create the query string for the url
    const query = {
      page: JSON.stringify(params.pagination.page),
      perPage: JSON.stringify(params.pagination.perPage),
      filter: JSON.stringify(params.filter),
      sort: JSON.stringify([params.sort.field, params.sort.order])
    }

    // url to the resource
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    
    return fetch(url, {
      method: "GET",
      type: "cors",
      headers: {
        'Accept': 'application/json',
        'Authorization': localStorage.getItem('authorization')
      }
    })
    .then(res => {
      //console.log(res.headers.values())
      return res.text().then(text => ({
        status: res.status,
        statusText: res.statusText,
        headers: res.headers,
        body: text
      }))
    })
    .then(({ status, statusText, headers, body }) => {
      console.log(headers)
      let json
      try {
        json = JSON.parse(body)
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        return Promise.reject(
          new HttpError(
            (json && json.message) || statusText,
              status,
              json
            )
          )
      }
      return Promise.resolve({
        data: json,
        total: 10
      })
    })
  },
  // Read a single resource, by id
  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),
  // Read a list of resource, by ids
  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => ({ data: json }))
  },
  // Read a list of resources related to another one
  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }))
  },*/

  /*update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids}),
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids}),
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  }*/
}
